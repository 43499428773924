import { useState, useEffect } from 'react';
import { auth } from '../utils/firebase';

interface AuthState {
  loading: boolean;
  user?: null|firebase.User
}

export const useAuth = () => {
  const [authState, setState] = useState<AuthState>({
    loading: true,
    user: null
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(authState =>
      setState({
        loading: false,
        user: authState
      })
    );
    return unsubscribe;
  }, []);

  return authState;
}
