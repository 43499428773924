import React, { SyntheticEvent, useState, useEffect, useCallback } from 'react';
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonList, IonReorder, IonItem, IonLabel, IonReorderGroup, IonIcon, IonInput, IonGrid, IonRow, IonCol, IonButton, IonAlert } from '@ionic/react';
import { add, trash } from 'ionicons/icons';
import { ItemReorderEventDetail } from '@ionic/core';
import uuidv4 from 'uuid/v4';
import { IBoard, ITask, IDepartment, IToast } from '../../types';
import { firestore } from '../../utils/firebase';
import './styles.scss';
import TaskCard from '../task-card';

interface CardProps {
  board: IBoard;
  search?: string;
  department: IDepartment;
  showToast: React.Dispatch<React.SetStateAction<IToast>>
}

const BoardLane: React.FC<CardProps> = ({ department, search, board, showToast }) => {

  const [newTask, setNewTask] = useState<ITask>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [confirm, showConfirm] = useState<boolean>(false);
  const [docRef, setDocRef] = useState<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>();

  useEffect(() => {
    const docReference = firestore.doc(`departments/${department.id}/boards/${board.id}`);
    setDocRef(docReference);
  }, [department, board.id]);

  const updateBoard = (key: string, value: any) => {
    if (!docRef) {
      return
    };

    docRef.set({
      ...board,
      [key]: value
    }, { merge: true }).then(() => {
      setEditMode(false);
    })
  }

  const addTasks = (e: CustomEvent<SyntheticEvent>) => {
    e.preventDefault();
    if (docRef && newTask && newTask.name) {
      const tasks = Object.assign([], board.tasks);
      const id = uuidv4();
      tasks.push({ id, status: 'progress', ...newTask });
      const newBoard = Object.assign({}, board, { tasks });
      docRef.set({
        ...newBoard
      }).then(() => {
        setNewTask({ name: '' });
      })
    }
  }

  const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    if (docRef && board) {
      event.detail.complete(board.tasks);
      docRef.set({
        ...board,
      }, { merge: true }).then(() => {});
    }
  }

  const confirmDelete = () => {
    docRef!.delete().then(() => {
      showToast({ show: true, message: "Board Deleted" });
    });
  }

  const getFilteredTasks = useCallback(() => {
    if (!board) {
      return [];
    }

    const tasks = board.tasks || [];
    if (search) {
      return tasks.filter(task => task.name.toLowerCase().includes(search.toLowerCase()));
    }
    return tasks;
  }, [search, board])

  if (!docRef) {
    return null;
  }

  return (
    <>
      <IonCard className="boardlane">
        <IonCardHeader color="dark">
          <IonGrid>
            <IonRow>
              <IonCol>
                {!editMode ?
                  <>
                    <IonCardTitle color="light">{board.name} <small>({(board.tasks || []).length})</small></IonCardTitle>
                    <IonCardSubtitle>{board.department}</IonCardSubtitle>
                  </>
                :
                  <>
                    <IonItem>
                      <IonInput type="text" value={board.name} onIonBlur={(e: any) => updateBoard('name', e.target.value)}></IonInput>
                    </IonItem>
                  </>
                }
              </IonCol>
              <IonCol className="dom-right ion-text-right">
                <IonButton size="small" color="light" onClick={() => setEditMode(!editMode)}>
                  {!editMode ? 'Edit' : 'Done'}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>
        <IonCardContent>
          <IonList>
            {!editMode &&
              <form onSubmit={(e: any) => addTasks(e)}>
                <IonItem lines="full">
                  <IonIcon icon={add} slot="start" />
                  <IonInput type="text" placeholder="Add Item" value={newTask?.name} onIonChange={(e: any) => setNewTask({ name: e.target.value })} />
                </IonItem>
              </form>
            }
            <IonReorderGroup disabled={!editMode} onIonItemReorder={doReorder}>
              {getFilteredTasks().map((task: ITask, index: number) => (
                <IonItem key={task.id} lines="none">
                  <TaskCard showToast={showToast} docReference={docRef} board={board} task={task} />
                  <IonReorder slot="end" style={{ marginLeft: 0 }}/>
                </IonItem>
              ))}
            </IonReorderGroup>
            {editMode && (
              <IonItem button lines="none" onClick={() => showConfirm(true)}>
                <IonIcon color="danger" icon={trash} slot="start" />
                <IonLabel color="danger">
                  Remove {board.name} from board?
                </IonLabel>
              </IonItem>
            )}
          </IonList>
        </IonCardContent>
      </IonCard>

      <IonAlert
        isOpen={confirm}
        onDidDismiss={() => showConfirm(false)}
        header="Delete"
        message={`Are you sure you want to remove ${board.name} from the board?`}
        buttons={[
          {
            text: "Yes",
            handler: () => {
              confirmDelete()
            }
          },
          {
            text: "No",
            role: "cancel",
            handler: () => { }
          }
        ]}
      />
    </>
  );
}

export default BoardLane;
