import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';

const app = firebase.initializeApp({
  // old [franco personal]
  // apiKey: "AIzaSyDdrOMU9RBj_-pRMORPKSGfa4PzH5o4Z2M",
  // authDomain: "scrum-board-168b9.firebaseapp.com",
  // databaseURL: "https://scrum-board-168b9.firebaseio.com",
  // projectId: "scrum-board-168b9",
  // storageBucket: "scrum-board-168b9.appspot.com",
  // messagingSenderId: "545990814345",
  // appId: "1:545990814345:web:6b78ece1b2785d8982b458",
  // measurementId: "G-6CGPM6P8PV",

  // new [union account]
  apiKey: "AIzaSyBmytojeTDBLPWpU9LMmmbt8HALv1WHFV4",
  authDomain: "scrum-board-11de4.firebaseapp.com",
  projectId: "scrum-board-11de4",
  storageBucket: "scrum-board-11de4.appspot.com",
  messagingSenderId: "112071041059",
  appId: "1:112071041059:web:899ae8a3e36286e5345196"
});

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const increment = firebase.firestore.FieldValue.increment;

export default app;
