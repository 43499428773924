import { IonItem, IonInput, IonButtons, IonIcon } from "@ionic/react";
import { checkmarkCircle, open } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import { ITask } from "../../types";

function isValidUrl(string: string) {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }

  return true;
}

const AddLinkPopover: React.FC<{ task: ITask; updateLink: (e: string) => void }> = ({ task, updateLink }) => {
  const [link, setLink] = useState<string>(task.link ? task.link : '');
  const [valid, setValid] = useState<boolean>(task.link ? isValidUrl(task.link) : false);

  useEffect(() => {
    if (link.length) {
      setValid(isValidUrl(link));
    }
  }, [link])

  return (
    <IonItem lines="none">
      <IonInput type="text" placeholder="Link" value={link} onIonChange={e => setLink(e.detail.value!)} onKeyPress={e => e.nativeEvent.charCode === 13 && updateLink(link)} />

      <IonButtons slot="end">
        {valid && <IonIcon icon={open} color="primary" className="u-clickable" onClick={(e: any) => window.open(task.link, "_blank")} />}
        <IonIcon color={valid ? 'success' : 'medium'} className="u-clickable" slot="end" icon={checkmarkCircle} onClick={() => {
          if (valid) {
            updateLink(link)
          }
        }} />
      </IonButtons>
    </IonItem>
  )
}

export default AddLinkPopover;
