import React, { useState, useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  IonFab,
  IonFabButton,
  IonIcon
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { expand, close } from 'ionicons/icons';

import { useAuth } from './hooks/use-auth';
import Auth from './pages/auth';
import MavenLink from './pages/auth/mavenlink';
import Board from './pages/board';
import Departments from './pages/departments';
import Loader from './pages/loader';
import Menu from './components/menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

function Wrapper() {
  const { loading, user } = useAuth();
  const history = useHistory();

  useEffect(
    () => {
      if (!loading && !user) {
        history.replace('/auth')
      }
    },
    [loading, user, history]
  );

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <IonSplitPane disabled contentId="main">
      <Menu />
      <IonRouterOutlet id="main">
        <Route path="/auth" exact={true} component={Auth} />
        <Route path="/departments" component={Departments} exact={true} />
        <Route path="/departments/:id" component={Board} exact={true} />
        <Route path="/mavenlink/callback" component={MavenLink} exact={true} />
        <Route path="/" render={() => user ? <Redirect to="/departments" /> : <Redirect to="/auth" />} exact={true} />
      </IonRouterOutlet>
    </IonSplitPane>
  )
}

const App: React.FC = (props: any) => {
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  useEffect(
    () => {
      document.addEventListener("fullscreenchange", (event) => {
        if (document.fullscreenElement) {
          setFullscreen(true);
        } else {
          setFullscreen(false);
        }
      });
    },
    []
  );

  const toggleFullscreen = () => {
    if (!fullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }

    setFullscreen(!fullscreen)
  }

  return (
    <IonApp>
      <IonReactRouter>
        <Wrapper />
      </IonReactRouter>

      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton color="dark" onClick={() => toggleFullscreen()}>
          <IonIcon icon={!fullscreen ? expand : close} />
        </IonFabButton>
      </IonFab>
    </IonApp>
  )
};

export default App;
