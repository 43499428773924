import React, { SyntheticEvent, useState } from 'react';
import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonList, IonItem, IonLabel, IonListHeader, IonIcon, IonButtons, IonMenuButton, useIonViewDidEnter, useIonViewWillLeave, IonModal, IonInput, IonSelect, IonSelectOption, IonButton, IonFooter } from '@ionic/react';
import { brush, laptop, gitNetwork, stats, videocam, trendingUp, close, add } from 'ionicons/icons';
import { firestore } from '../../utils/firebase';
import './styles.scss';
import { IDepartment } from '../../types';
import SkeletonList from '../../components/skeleton-list';

const IconMap: { [key: string]: any } = {
  brush,
  laptop,
  gitNetwork,
  stats,
  videocam,
  trendingUp
}

const IconMapLabel: { [key: string]: any } = [
  { label: 'Brush', value: 'brush' },
  { label: 'Laptop', value: 'laptop' },
  { label: 'Git Network', value: 'gitNetwork' },
  { label: 'Stats', value: 'stats' },
  { label: 'Video Cam', value: 'videocam' },
  { label: 'Trending Up', value: 'trendingUp' },
]

let unsubscribe: Function|null = null;
const Departments: React.FC = () => {
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [adBoard, showAdBoard] = useState<boolean>(false);
  const [newBoardTitle, setNewBoardTitle] = useState<string>("");
  const [newBoardIcon, setNewBoardIcon] = useState<string>("brush");

  useIonViewDidEnter(
    () => {
      unsubscribe = firestore.collection('departments').onSnapshot((snapshot) => {
        if (snapshot.size) {
          setDepartments(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as IDepartment)))
        }

        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
    },
    []
  )

  useIonViewWillLeave(
    () => {
      if (unsubscribe) {
        unsubscribe();
      }
    }
  )

  const addNewBoard = (e?: SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }

    firestore.collection('departments').add({
      name: newBoardTitle,
      icon: newBoardIcon
    }).then(() => {
      showAdBoard(false);
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="dark">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Departments</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="departments">
        <div className="centered">
          {loading && <SkeletonList header="Departments" items={5} />}
          {!loading &&
            <IonList>
              <IonListHeader>
                Departments
              </IonListHeader>
              {departments && departments.map((department: IDepartment, index: number) => {
                return (
                  <IonItem key={department.id} lines="full" detail routerLink={`/departments/${department.id}`}>
                    <IonLabel>
                      <h2>{department.name}</h2>
                    </IonLabel>
                    <IonIcon slot="start" icon={IconMap[department.icon]}></IonIcon>
                  </IonItem>
                )
              })
              }
              <IonItem className="u-clickable" lines="none" key="adder" onClick={() => showAdBoard(true)}>
                <IonIcon slot="start" icon={add}></IonIcon>
                <IonLabel>Add Board</IonLabel>
              </IonItem>
            </IonList>
          }
        </div>
      </IonContent>
      <IonModal isOpen={adBoard} onDidDismiss={() => showAdBoard(false)}>
        <IonHeader>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <IonButton slot="icon-only" onClick={() => showAdBoard(false)}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Add Board</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={(e) => addNewBoard(e)}>
          <IonItem>
            <IonLabel>Board Name</IonLabel>
            <IonInput type="text" name="boardName" placeholder="Experiences" value={newBoardTitle} onIonChange={(e) => setNewBoardTitle(e.detail.value!)}/>
          </IonItem>
          <IonItem>
            <IonLabel>Board Icon</IonLabel>
            <IonSelect value={newBoardIcon} placeholder="Brush" onIonChange={(e) => setNewBoardIcon(e.detail.value)}>
              {IconMapLabel.map(({ label, value }: any, index: number) => (
                <IonSelectOption key={index} value={value}>{label}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          </form>
        </IonContent>
        <IonFooter>
          <IonToolbar color="dark" style={{ '--padding-top': 0, '--padding-bottom': 0 }}>
            <IonButton onClick={() => addNewBoard()} color="dark" type="submit" expand="full">
              Save
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </IonPage>
  );
};

export default Departments;
