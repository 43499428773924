import React, { useEffect, useState } from 'react';
import { IonPage, IonContent, IonImg } from '@ionic/react';
import { useAuth } from '../../hooks/use-auth';
import { firestore } from '../../utils/firebase';
import qs from 'qs';
import './styles.scss';

const MavenLink: React.FC = () => {
  const [error, setError] = useState<string>();
  const [message, setMessage] = useState<string>();
  const { user } = useAuth();

  const getAuthCode = async (code: string, user: firebase.User) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_FIREBASE_URL}/authenticate?code=${code}&redirect=${process.env.REACT_APP_ML_REDIRECT}`);
      const data = await response.json();
      if (data.access_token) {
        firestore.doc(`mavenlink/${user.uid}`).set({
          token: data.access_token
        }).then(() => {
          window.close();
          setMessage('Authentication is complete. You can close this window.')
        })
      } else {
        console.log(data);
        setError(data.error)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!user) {
      return;
    }

    const params = qs.parse(window.location.search.substring(1));
    if (params.code) {
      console.log('code', params.code);
      getAuthCode(params.code as string, user);
    }
  }, [user])

  return (
    <IonPage className="auth">
      <IonContent>
        <IonImg src="assets/images/culture-1.jpg" />
        <main>
          <div>
            <h1>Authenticating with MavenLink</h1>
            {error && <h4>There was an error with MavenLink Auth<br/>{error}</h4>}
            {message && <h4>{message}</h4>}
          </div>
        </main>
      </IonContent>
    </IonPage>
  );
};

export default MavenLink;
