import React, { useEffect, useState } from 'react';
import { IonContent, IonAvatar, IonButton, IonIcon, IonImg, IonItem, IonLabel, IonList, IonRefresher, IonRefresherContent } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import { firestore } from '../../utils/firebase';
import { useAuth } from '../../hooks/use-auth';
import { getMavenLinkUrl } from '../../utils/mavenlink';
import { MLPost, MLPostsResponse } from '../../types';
import { open } from 'ionicons/icons';
import './styles.scss';

interface MentionProps {}

const Mentions: React.FC<MentionProps> = () => {
  const { user } = useAuth();
  const [token, setToken] = useState<string>();
  const [response, setResponse] = useState<MLPostsResponse>();

  useEffect(() => {
    if (!user || !token) {
      return;
    }
    let mounted = true;
    fetch(`${process.env.REACT_APP_FIREBASE_URL}/posts?token=${token}&term=@${user.displayName?.replace(' ', '')}`)
      .then(res => res.json())
      .then((data: MLPostsResponse) => {
        if (mounted) {
          setResponse(data);
        }
      })

    return () => {
      mounted = false;
    }
  }, [user, token])

  useEffect(() => {
    if (user) {
      firestore.doc(`mavenlink/${user.uid}`).onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          setToken(data!.token)
        }
      })
    }
  }, [user])

  const doRefresh = (event: CustomEvent<RefresherEventDetail>, user: firebase.User) => {
    fetch(`${process.env.REACT_APP_FIREBASE_URL}/posts?token=${token}&term=@${user.displayName?.replace(' ', '')}`)
      .then(res => res.json())
      .then((data: MLPostsResponse) => {
        setResponse(data);
        event.detail.complete();
      })
  }

  if (!token) {
    return (
      <div className="mentions__login">
        <IonButton href={getMavenLinkUrl()} target="_blank">
          Connect to Mavenlink
        </IonButton>
      </div>
    );
  }

  return (
    <IonContent style={{ '--background': 'white' }}>
      <IonRefresher slot="fixed" onIonRefresh={(event) => doRefresh(event, user!)}>
        <IonRefresherContent
          pullingText="Pull to refresh"
          refreshingText="Fetching latest mentions..."
        ></IonRefresherContent>
      </IonRefresher>
      <div className="mentions">
        <IonList>
          {response && response.posts && Object.values(response.posts).map((post: MLPost) => {
            const user = response.users[+post.user_id];
            const story = post.story_id ? response.stories[+post.story_id] : null;
            const workspace = response.workspaces[+post.workspace_id];

            return (
              <IonItem key={post.id}>
                <IonAvatar slot="start" className="ion-align-self-start ion-margin-top">
                  <IonImg src={user.photo_path} />
                </IonAvatar>
                <IonLabel className="ion-text-wrap">
                  <h3>{workspace.title} {story && <>/ {story.title}</>}</h3>

                  <div className="ion-padding-vertical">
                    <h4>{user.full_name} wrote on {(new Date(post.updated_at)).toLocaleDateString()}:</h4>
                    <p className="ion-no-padding" dangerouslySetInnerHTML={{ __html: post.formatted_message }} />
                    {post.reply_count > 0 && <p>+ {post.reply_count} replies</p>}
                  </div>
                </IonLabel>
                <IonButton
                  className="ion-align-self-start ion-margin-top"
                  fill="clear"
                  size="small"
                  slot="end"
                  href={`https://app.mavenlink.com/workspaces/${post.workspace_id}/#tracker/${post.story_id || ''}`}
                  target="_blank"
                >
                  <IonIcon icon={open} />
                </IonButton>
              </IonItem>
            )
          })}
        </IonList>
      </div>
    </IonContent>
  );
}

export default Mentions;
