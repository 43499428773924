import React from 'react';
import { IonList, IonItem, IonLabel, IonSkeletonText, IonListHeader } from '@ionic/react';
// import './styles.scss';

const SkeletonList: React.FC<{ header?: string; items?: number }> = ({ header, items = 10 }) => {
  return (
    <IonList>
      {header &&
        <IonListHeader>
          {header}
        </IonListHeader>
      }
      {Array.from(Array(items).keys()).map((_, index: number) => (
        <IonItem key={index} lines="full">
          <IonLabel>
            <IonSkeletonText style={{ height: '18px', width: '300px' }} animated />
          </IonLabel>
        </IonItem>
      ))}
    </IonList>
  );
};

export default SkeletonList;
