import React from 'react';
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import './styles.scss';

interface Props {
  title: string;
  isOpen: boolean;
  onDidDismiss: () => void;
}

const Drawer: React.FC<Props> = ({
  title,
  isOpen = false,
  onDidDismiss,
  children
}) => {
  return (
    <>
      {isOpen && <div className={`drawer-overlay ${isOpen ? 'is-open' : ''}`} onClick={onDidDismiss}></div>}
      <div className={`drawer ${isOpen ? 'is-open' : ''}`}>
        <IonHeader>
          <IonToolbar color="dark">
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end" onClick={onDidDismiss}>
              <IonButton>
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {children}
      </div>
    </>
  );
}

export default Drawer;
