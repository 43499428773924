import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon, IonList, IonItem, IonLabel, IonInput, IonFooter, IonGrid, IonRow, IonCol } from '@ionic/react';
import { close } from 'ionicons/icons';
import uuidv4 from 'uuid/v4';
import { firestore } from '../../utils/firebase';
import { IBoard, IDepartment } from '../../types';
import './styles.scss';

const defaultForm = {
  id: '',
  name: '',
  department: '',
}

const AddPersonModal: React.FC<{ department: IDepartment, closeModal: Function }> = ({ department, closeModal }) => {

  const [form, setForm] = useState<IBoard>(Object.assign({}, defaultForm, { department: department.name }));

  const onSave = () => {
    const id = uuidv4();
    firestore.doc(`departments/${department.id}/boards/${id}`).set({
      ...form,
      tasks: [],
      id
    }).then(() => {
      beforeCloseModal();
    });
  }

  const beforeCloseModal = () => {
    setForm({ id: '', name: '', department: '' });
    setTimeout(() => {
      closeModal();
    });
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Person</IonTitle>
          <IonButtons slot="end">
            <IonButton slot="icon-only" onClick={() => beforeCloseModal()}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel position="floating">Name</IonLabel>
            <IonInput type="text" value={form.name} onIonChange={(e: any) => setForm({ ...form, name: e.target.value })}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Department</IonLabel>
            <IonInput type="text" value={form.department} disabled></IonInput>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter className="addperson">
        <IonGrid>
          <IonRow className="ion-justify-content-between">
            <IonCol size="auto">
              <IonButton color="danger" fill="clear" onClick={() => beforeCloseModal()}>Cancel</IonButton>
            </IonCol>
            <IonCol size="auto">
              <IonButton color="dark" onClick={() => onSave()}>Save</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </>
  );
}

export default AddPersonModal;
