import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonPage, IonHeader, IonToolbar, IonToast, IonTitle, IonContent, IonButtons, IonButton, IonIcon, IonModal, IonLoading, useIonViewDidEnter, useIonViewDidLeave, IonBackButton, IonSearchbar } from '@ionic/react';
import { add, chatboxes } from 'ionicons/icons';
import AddPersonModal from '../../modals/add-person';
// import BoardCard from '../../components/board-card';
import { IBoard, IDepartment, IToast } from '../../types';
import { firestore } from '../../utils/firebase';
import { Drawer } from '../../components';
import { useAuth } from '../../hooks/use-auth';
import Mentions from '../../components/mentions';
import BoardLane from '../../components/board-lane';
import './styles.scss';

type BoardProps = RouteComponentProps<{ id: string }>;

const Board: React.FC<BoardProps> = (props: BoardProps) => {
  const [addModal, showAddModal] = useState<boolean>(false);
  const [toast, showToast] = useState<IToast>({ show: false, message: '' });
  const [loading, setLoading] = useState<boolean>(true);
  const [department, setDepartment] = useState<IDepartment>();
  const [boards, setBoards] = useState<IBoard[]>([]);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const { user } = useAuth();

  const { id } = props.match.params;

  useIonViewDidEnter(() => {
    setLoading(true);

    firestore.doc(`departments/${id}`).get().then((doc) => {
      setDepartment({
        id: doc.id,
        ...doc.data()
      } as IDepartment);
    });

    firestore.collection(`departments/${id}/boards`).onSnapshot((snapshot) => {
      if (snapshot.size) {
        setBoards(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as IBoard)))
      }
      setLoading(false);
    })
  }, [id, user]);

  useIonViewDidLeave(
    () => {
      setDepartment(undefined);
      setBoards([]);
    }
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="dark">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/departments"/>
          </IonButtons>
          <div className="toolbar__withsearch">
            <IonTitle>
              {department && `${department?.name} Scrum Board`}
            </IonTitle>
            <IonSearchbar
              value={search}
              onIonChange={(event) => setSearch(event.detail.value!)}
            />
          </div>
          <IonButtons slot="end">
            <IonButton slot="icon-only" onClick={() => setDrawer(true)}>
              <IonIcon icon={chatboxes} />
            </IonButton>
            <IonButton slot="icon-only" onClick={() => showAddModal(true)}>
              <IonIcon icon={add} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading && <IonLoading isOpen={true} />}
        {department && boards &&
          <div className="scrumboard">
            {boards.sort((a, b) => a.name.localeCompare(b.name)).map((board: IBoard, index: number) => {
              return (
                <BoardLane
                  key={index}
                  board={board}
                  search={search}
                  department={department}
                  showToast={showToast}
                />
              );
            })}
          </div>
        }
      </IonContent>
      <Drawer
        title="Mentions"
        isOpen={drawer}
        onDidDismiss={() => setDrawer(false)}
      >
        {/* <RoadBlocks departmentId={id} /> */}
        <Mentions />
      </Drawer>
      <IonModal
        isOpen={addModal}
        onDidDismiss={() => showAddModal(false)}>
        {addModal && department && <AddPersonModal department={department} closeModal={() => showAddModal(false)} />}
      </IonModal>
      <IonToast
        isOpen={toast.show}
        onDidDismiss={() => showToast({ show: false, message: '' })}
        message={toast.message}
        duration={4000}
        position="top"
        color="light"
      />
    </IonPage>
  );
}

export default Board;
