import { IonAlert, IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPopover, IonRadio, IonRadioGroup, IonRow, IonTextarea } from '@ionic/react';
import { link, star, starOutline, trash } from 'ionicons/icons';
import React, { useState } from 'react';
import { IBoard, ITask, IToast } from '../../types';
import AddLinkPopover from '../add-link-popover';
import './styles.scss';

interface TaskCardProps {
  task: ITask;
  board: IBoard;
  showToast: React.Dispatch<React.SetStateAction<IToast>>;
  docReference: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
}

const StatusMap = {
  progress: 'primary',
  staging: 'medium',
  blocked: 'dark',
  completed: 'success',
  hold: 'danger',
}

const TaskCard: React.FC<TaskCardProps> = ({ docReference, showToast, board, task }) => {
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [statusPopper, setStatusPopper] = useState({ showPopover: false, event: undefined });
  const [addLink, setAddLink] = useState<{ task?: ITask; isOpen: boolean; event?: any; }>({
    isOpen: false,
  });
  // const [completion, setCompletion] = useState<number>(task.completion || 0);

  const [name, setName] = useState<string>(task.name);

  const updateTask = (updated: ITask) => {
    const taskIndex = board.tasks!.findIndex(t => t.id! === updated.id!);
    if (board && board.tasks) {
      board.tasks[taskIndex] = {
        ...updated
      }

      docReference.set({
        ...board,
      }).then(() => {
        if (statusPopper.showPopover) {
          setStatusPopper({ showPopover: false, event: undefined });
        }
        if (addLink.isOpen) {
          setAddLink({ event: undefined, task: undefined, isOpen: false });
        }
      });
    }
  }

  const deleteTask = () => {
    if (board && board.tasks) {
      const tasks = board.tasks.filter(t => t.id !== task.id);
      board.tasks = tasks;

      docReference.set({
        ...board,
      }).then(() => {
        showToast({ show: true, message: "Task Deleted" });
      });
    }
  }

  // const updateProgress = () => {
  //   updateTask({
  //     ...task,
  //     completion
  //   })
  // };

  return (
    <>
      <IonCard className="taskcard" style={{ '--border-style': `4px solid var(--ion-color-${StatusMap[task.status || 'progress']})`}}>
        <IonCardContent>
          <div data-task-id={task.id}>
            {!editMode ? (
              <p onDoubleClick={() => setEditMode(true)}>{task.name}</p>
            ) : (
              <IonTextarea rows={2} value={name} onIonChange={e => setName(e.detail.value!)} />
            )}

            <IonGrid>
              <IonRow>
                <IonCol>
                  {task.starred ?
                    <IonIcon className="u-clickable" icon={star} color="warning" onClick={() => updateTask({ ...task, starred: false })} />
                    :
                    <IonIcon className="u-clickable" icon={starOutline} color="warning" onClick={() => updateTask({ ...task, starred: true })} />
                  }
                  <IonIcon
                    className="u-clickable"
                    style={{ marginLeft: '8px' }}
                    color={task.link ? 'primary' : 'medium'}
                    icon={link}
                    onClick={(e: any) => setAddLink({
                      event: e.nativeEvent,
                      task,
                      isOpen: true,
                    })}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <IonGrid>
            <IonRow className="ion-justify-content-between">
              <IonCol size="auto">
                <IonButton color={StatusMap[task.status!]} size="small" fill="clear" onClick={(e: any) => {
                  e.persist();
                  setStatusPopper({ showPopover: true, event: e })
                }}>
                  {task.status || 'Progress'}
                </IonButton>
              </IonCol>
              {/* <IonCol className="progressbar__wrapper">
                <IonRange
                  debounce={700}
                  pin={true}
                  step={5}
                  value={completion}
                  onIonBlur={() => updateProgress()}
                  onIonChange={e => setCompletion(e.detail.value as number)} />
              </IonCol> */}
              <IonCol size="auto" className="ion-text-right">
                {!editMode ? (
                  <IonButton size="small" fill="clear" onClick={() => setEditMode(true)}>
                    Edit
                  </IonButton>
                ) : (
                  <IonButton size="small" fill="clear" onClick={() => {
                    updateTask({ ...task, name })
                    setEditMode(false)
                  }}>
                    Save
                  </IonButton>
                )}
                <IonButton size="small" fill="clear" onClick={() => setDeleteAlert(true)}>
                  <IonIcon icon={trash} color="danger" slot="icon-only" />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
      <IonPopover
        isOpen={statusPopper.showPopover}
        event={statusPopper.event}
        onDidDismiss={() => setStatusPopper({ showPopover: false, event: undefined })}
      >
        <IonList>
          <IonRadioGroup value={task.status || 'progress'} onIonChange={e => {
            updateTask({ ...task, status: e.detail.value });
          }}>
            <IonItem lines="none">
              <IonLabel color="primary">Progress</IonLabel>
              <IonRadio color="primary" slot="start" value="progress" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel color="medium">Staging</IonLabel>
              <IonRadio color="medium" slot="start" value="staging" />
            </IonItem>
            <IonItem lines="full">
              <IonLabel color="success">Completed</IonLabel>
              <IonRadio color="success" slot="start" value="completed" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel color="dark">Blocked</IonLabel>
              <IonRadio color="dark" slot="start" value="blocked" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel color="danger">Hold</IonLabel>
              <IonRadio color="danger" slot="start" value="hold" />
            </IonItem>
          </IonRadioGroup>
        </IonList>
      </IonPopover>
      <IonPopover
        event={addLink.event}
        isOpen={addLink.isOpen}
        onDidDismiss={e => setAddLink({ event: undefined, task: undefined, isOpen: false })}
      >
        {addLink.task &&
          <AddLinkPopover
            task={addLink.task}
            updateLink={(link) => {
              updateTask({ ...addLink.task!, link });
              // setEditMode(false);
            }}
          />
        }
      </IonPopover>
      <IonAlert
        isOpen={deleteAlert}
        onDidDismiss={() => setDeleteAlert(false)}
        header={'Confirm'}
        message={'Are you sure you want to delete this task?'}
        buttons={[
          'Cancel',
          {
            text: 'Delete',
            handler: () => {
              setDeleteAlert(false)
              setTimeout(() => {
                deleteTask();
              }, 250);
            }
          }
        ]}
      />
    </>
  );
}

export default TaskCard;
