import React from 'react';
import { IonPage, IonContent, IonImg, IonLoading } from '@ionic/react';
import './styles.scss';

const Loader: React.FC = () => {
  return (
    <IonPage className="loader">
      <IonContent>
        <IonImg src="assets/images/culture-1.jpg" />
        <IonLoading isOpen={true} />
      </IonContent>
    </IonPage>
  );
};

export default Loader;
