import React, { useEffect } from 'react';
import { IonPage, IonContent, IonImg, IonButton, IonIcon } from '@ionic/react';
import { logoGoogle } from 'ionicons/icons';
import firebase from "firebase/app";
import "firebase/auth";
import { auth } from '../../utils/firebase';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';

const Auth: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  useEffect(
    () => {
      if (user) {
        history.push('/departments');
      }
    },
    [user, history]
  )

  const login = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    auth.signInWithPopup(provider).then((result) => {
      console.log('result', result);
    }).catch((error) => {
      console.log('error', error);
    });
  }

  return (
    <IonPage className="auth">
      <IonContent>
        <IonImg src="assets/images/culture-1.jpg" />
        <main>
          <div>
            <h1>Scrum Board</h1>
            <IonButton color="dark" onClick={() => login()}>
              <IonIcon icon={logoGoogle} slot="start"/>
              Login with Google
            </IonButton>
          </div>
        </main>
      </IonContent>
    </IonPage>
  );
};

export default Auth;
