import React, { useState, useEffect, useRef } from 'react';
import { IonContent, IonListHeader, IonMenu, IonHeader, IonToolbar, IonTitle, IonList, IonItem, IonLabel, IonIcon, IonAlert, IonMenuToggle, IonAvatar, IonImg, IonButton, IonButtons } from '@ionic/react';
import { brush, laptop, gitNetwork, stats, videocam, logOut as logoutIcon, list } from 'ionicons/icons';
import { firestore, auth } from '../../utils/firebase';
import { IDepartment } from '../../types';
import SkeletonList from '../skeleton-list';
import { useAuth } from '../../hooks/use-auth';
import './styles.scss';

const IconMap: { [key: string]: any } = {
  brush,
  laptop,
  gitNetwork,
  stats,
  videocam
}

let unsubscribe: Function|null = null;

const Menu: React.FC = () => {
  const { user } = useAuth();
  const menuRef = useRef<HTMLIonMenuElement>(null);
  const [logout, showLogout] = useState<boolean>(false);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(
    () => {
      if (user) {
        unsubscribe = firestore.collection('departments').onSnapshot((snapshot) => {
          if (snapshot.size) {
            setDepartments(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as IDepartment)))
          }

          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
      }

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    },
    [user]
  )

  return (
    <>
      <IonMenu className="mainmenu" type="overlay" contentId="main" ref={menuRef}>
        <IonHeader>
          <IonToolbar color="dark">
            <IonTitle>Union</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {user &&
          <>
            <IonList className="profile">
              <IonItem>
                <IonAvatar slot="start">
                  <IonImg src={user.photoURL ? user.photoURL : `https://placehold.it/250x250.png?text=${user.displayName?.slice(0, 1)}`} />
                </IonAvatar>
                <IonLabel>
                  <h2>{user.displayName}</h2>
                  <p>{user.email}</p>
                </IonLabel>
                <IonButtons slot="end">
                  <IonButton slot="icon-only" onClick={() => showLogout(true)}>
                    <IonIcon icon={logoutIcon} />
                  </IonButton>
                </IonButtons>
              </IonItem>
            </IonList>
            <IonList>
              <IonListHeader>Departments</IonListHeader>
              <IonMenuToggle autoHide={false}>
                <IonItem lines="full" detail routerLink="/departments">
                  <IonLabel>
                    <h2>All</h2>
                  </IonLabel>
                  <IonIcon slot="start" icon={list}></IonIcon>
                </IonItem>
              </IonMenuToggle>
              {loading && <SkeletonList items={5} />}
              {departments && departments.map((department: IDepartment, index: number) => {
                return (
                  <IonMenuToggle key={department.id} autoHide={false}>
                    <IonItem lines="full" detail routerLink={`/departments/${department.id}`}>
                      <IonLabel>
                        <h2>{department.name}</h2>
                      </IonLabel>
                      <IonIcon slot="start" icon={IconMap[department.icon]}></IonIcon>
                    </IonItem>
                  </IonMenuToggle>
                )
              })
              }
            </IonList>
          </>
          }
        </IonContent>
      </IonMenu>
      <IonAlert
        isOpen={logout}
        onDidDismiss={() => showLogout(false)}
        header="Logout"
        message="Are you sure you want to log out?"
        buttons={[
          {
            text: "Yes",
            handler: () => {
              showLogout(false);
              setTimeout(() => {
                auth.signOut();
                menuRef.current!.close();
              }, 500);
            }
          },
          {
            text: "No",
            role: "cancel",
            handler: () => { }
          }
        ]}
      />
    </>
  );
};

export default Menu;
